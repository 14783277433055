import React from "react";
import { Card, Typography, Form, Input, Select, Checkbox, Button, Divider, Row, Col, Radio, Space } from "antd";
import { useLocation } from "react-router-dom";

const { Title, Text } = Typography;
const { Option } = Select;

const BookingPage = () => {
  const location = useLocation();
  const { selectedHotel } = location.state || {}; // Retrieve hotel data

  if (!selectedHotel) {
    return <Text>No hotel selected. Please go back and select a hotel.</Text>;
  }

  return (
    <div style={{ padding: "20px", fontFamily: "'Arial', sans-serif" }}>
      <Title level={3}>Confirm Your Stay</Title>
      <Row gutter={[16, 16]}>
        {/* Left Column */}
        <Col xs={24} md={8}>
          <Card>
            <Title level={4}>{selectedHotel.name || "Room Type"}</Title> {/* Dynamic Room Type */}
            <Text>
              <strong>Check-in:</strong> {selectedHotel.check_in || "Check-in Date"} <br />
              <strong>Check-out:</strong> {selectedHotel.check_out || "Check-out Date"}<br />
              <strong>Location:</strong>{selectedHotel.location || ""}
            </Text>
            <Divider />
            <Row justify="space-between" style={{ marginBottom: "10px" }}>
              <Text>Guests:</Text>
              <Text>{selectedHotel.guest_capacity || 1} Adult(s)</Text> {/* Dynamic Guest Capacity */}
            </Row>
            <Row justify="space-between">
              <Text>Total:</Text>
              <Text style={{ fontWeight: "bold" }}>
                TZS {selectedHotel.price_per_night || "0.00"}
              </Text>
            </Row>
          </Card>
        </Col>

        {/* Right Column */}
        <Col xs={24} md={16}>
          {/* Booking Details Form */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Enter your details</Title>
            <Form layout="vertical">
              <Form.Item label="First Name" name="firstName" required>
                <Input placeholder="Enter your first name" />
              </Form.Item>
              <Form.Item label="Last Name" name="lastName" required>
                <Input placeholder="Enter your last name" />
              </Form.Item>
              <Form.Item label="Email Address" name="email" required>
                <Input placeholder="Enter your email address" />
              </Form.Item>
              <Form.Item label="Phone Number" name="phone">
                <Input placeholder="Enter your phone number" />
              </Form.Item>
              <Form.Item>
                <Checkbox>Send me a confirmation via WhatsApp</Checkbox>
              </Form.Item>
            </Form>
          </Card>

          {/* Room Information */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>{selectedHotel.roomType || "Mixed Dormitory Room"}</Title>
            <Text>Pay nothing until {selectedHotel.check_in || "Check-in Date"}</Text>
            <Radio.Group defaultValue="mixedRoom" style={{ marginTop: "10px" }}>
              <Space direction="vertical">
                <Radio value="mixedRoom">1 room for TZS {selectedHotel.price_per_night || "0.00"}</Radio>
              </Space>
            </Radio.Group>
          </Card>

          {/* Add to Your Stay */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Add to your stay</Title>
            <Checkbox.Group>
              <Row>
                <Col span={24}>
                  <Checkbox value="breakfast">Breakfast (TZS 15,000)</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="airportTransfer">Airport Transfer (TZS 20,000)</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Card>

          {/* Special Requests */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Special Requests</Title>
            <Form.Item>
              <Input.TextArea placeholder="Write your special requests here" rows={3} />
            </Form.Item>
          </Card>

          {/* Arrival Time */}
          <Card style={{ marginTop: "20px" }}>
            <Title level={5}>Your arrival time</Title>
            <Select placeholder="Select your estimated arrival time" style={{ width: "100%" }}>
              <Option value="before12">Before 12:00 PM</Option>
              <Option value="12to3">12:00 PM - 3:00 PM</Option>
              <Option value="after3">After 3:00 PM</Option>
            </Select>
          </Card>

          {/* Submit Button */}
          <Button type="primary" style={{ marginTop: "20px", width: "100%" }}>
            Finalize Booking
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default BookingPage;



