import React, { useState } from "react";
import { Table, Button, Select, Typography, DatePicker } from "antd";
import { UserOutlined, CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const data = [
  {
    key: "1",
    roomType: "Double Room",
    guests: 2,
    price: "TZS 502,080",
    taxes: "+TZS 52,851 taxes and fees",
    breakfast: "Very good breakfast included",
    cancellation: "Total cost to cancel",
    prepayment: "No prepayment needed – pay at the property",
    availability: "Only 4 rooms left on our site",
  },
  {
    key: "2",
    roomType: "Standard Twin Room",
    guests: 2,
    price: "TZS 502,080",
    taxes: "+TZS 52,851 taxes and fees",
    breakfast: "Very good breakfast included",
    cancellation: "Total cost to cancel",
    prepayment: "No prepayment needed – pay at the property",
    availability: "Only 1 room left on our site",
  },
  {
    key: "3",
    roomType: "Twin Room with Garden View",
    guests: 2,
    price: "TZS 502,080",
    taxes: "+TZS 52,851 taxes and fees",
    breakfast: "Very good breakfast included",
    cancellation: "Total cost to cancel",
    prepayment: "No prepayment needed – pay at the property",
    availability: "Only 2 rooms left on our site",
  },
];

const styles = {
  container: {
    maxWidth: "100%",
    margin: "auto",
    padding: "20px",
  },
  filterSection: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    border: "2px solid #ffae00",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "20px",
  },
  rangePicker: {
    border: "none",
    flex: 1,
  },
  guestSelect: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    border: "2px solid #0073e6",
    borderRadius: "5px",
    padding: "5px",
  },
  changeSearchButton: {
    backgroundColor: "#0073e6",
    color: "#fff",
    border: "none",
    padding: "8px 15px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  roomTitle: {
    color: "#0073e6",
    cursor: "pointer",
    fontWeight: "bold",
  },
  guestIcons: {
    fontSize: "16px",
  },
  choices: {
    color: "#333",
  },
  availability: {
    color: "#ff4d4f",
  },
  roomSelect: {
    width: "60px",
  },
  reserveButton: {
    width: "100%",
    marginTop: "15px",
    backgroundColor: "#1890ff",
    border: "none",
    color: "#fff",
    fontSize: "16px",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
  },
};

const HotelAvailability = () => {
  const [dates, setDates] = useState([dayjs(), dayjs().add(2, "day")]);
  const [guests, setGuests] = useState(2);
  const [rooms, setRooms] = useState(1);

  const handleDateChange = (dates) => setDates(dates);
  const handleGuestChange = (value) => setGuests(value);
  const handleRoomChange = (value) => setRooms(value);

  const columns = [
    {
      title: "Room type",
      dataIndex: "roomType",
      key: "roomType",
      render: (text) => <Text style={styles.roomTitle}>{text}</Text>,
    },
    {
      title: "Number of guests",
      dataIndex: "guests",
      key: "guests",
      render: () => (
        <div style={styles.guestIcons}>
          <UserOutlined /> <UserOutlined />
        </div>
      ),
    },
    {
      title: "Price for 2 nights",
      dataIndex: "price",
      key: "price",
      render: (text, record) => (
        <div>
          <Text strong>{text}</Text>
          <br />
          <Text type="secondary">{record.taxes}</Text>
        </div>
      ),
    },
    {
      title: "Your choices",
      key: "choices",
      render: (_, record) => (
        <div style={styles.choices}>
          <Text type="success">{record.breakfast}</Text>
          <br />
          <Text>{record.cancellation}</Text>
          <br />
          <Text>{record.prepayment}</Text>
          <br />
          <Text style={styles.availability}>{record.availability}</Text>
        </div>
      ),
    },
    {
      title: "Select Rooms",
      key: "select",
      render: () => (
        <Select defaultValue="0" style={styles.roomSelect}>
          <Option value="0">0</Option>
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
        </Select>
      ),
    },
    {
      title: "I'll reserve",
      key: "reserve",
      render: () => (
        <Button type="primary" >
        Reserve
      </Button>
      ),
    },
  ];

  return (
    <div style={styles.container}>
      {/* Search Filter Section */}
      <div style={styles.filterSection}>
        <RangePicker
          defaultValue={dates}
          onChange={handleDateChange}
          format="ddd, MMM D"
          style={styles.rangePicker}
          suffixIcon={<CalendarOutlined />}
        />
        <div style={styles.guestSelect}>
          <UserOutlined style={{ marginRight: "5px" }} />
          <Select value={guests} onChange={handleGuestChange} bordered={false}>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              <Option key={num} value={num}>
                {num} {num > 1 ? "adults" : "adult"}
              </Option>
            ))}
          </Select>
          <span> · </span>
          <Select defaultValue={0} bordered={false}>
            {[0, 1, 2, 3, 4].map((num) => (
              <Option key={num} value={num}>
                {num} {num === 1 ? "child" : "children"}
              </Option>
            ))}
          </Select>
          <span> · </span>
          <Select value={rooms} onChange={handleRoomChange} bordered={false}>
            {[1, 2, 3, 4, 5].map((num) => (
              <Option key={num} value={num}>
                {num} {num > 1 ? "rooms" : "room"}
              </Option>
            ))}
          </Select>
        </div>
        <Button type="primary" style={styles.changeSearchButton}>
          Change search
        </Button>
      </div>

      {/* Hotel Availability Table */}
      <Table columns={columns} dataSource={data} pagination={false} />

    </div>
  );
};

export default HotelAvailability;
