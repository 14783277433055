import React, { useState } from "react";
import { Card, Button, Rate, Progress } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const reviewsData = [
  { id: 1, name: "Beth", country: "South Africa", rating: 8.5, text: "Quaint and authentic. Loved the rooftop dining and the pool was a blessing." },
  { id: 2, name: "Ayesha", country: "United States", rating: 9.0, text: "This is a really quaint and beautiful hotel, very conveniently located to the port if you are taking the ferry. The staff are exceptional and go above and beyond to help. The rooms are spacious and very comfortable. Breakfast was amazing." },
  { id: 3, name: "Christopher", country: "United States", rating: 7.8, text: "Decor was awesome, pool relaxing, views from many different locations throughout, and Freddy Mercury bar and night market across the street." },
  { id: 4, name: "Noel", country: "Tanzania", rating: 8.9, text: "The hotel staff were very friendly. The cleanliness was top-notch. Breakfast was great. Loved the experience." },
  { id: 5, name: "Emily", country: "Canada", rating: 7.2, text: "Great experience overall, though the internet was a bit slow. Loved the atmosphere!" },
];

const ReviewsComponent = () => {
  const [expandedReviews, setExpandedReviews] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const reviewsPerPage = 4;

  const handleExpand = (id) => {
    setExpandedReviews((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const nextSlide = () => {
    if (currentIndex + reviewsPerPage < reviewsData.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const averageRating = (
    reviewsData.reduce((sum, review) => sum + review.rating, 0) / reviewsData.length
  ).toFixed(1);

  return (
    <div style={{ maxWidth: "900px", margin: "auto" }}>
      <h2>Guest Reviews</h2>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <h3 style={{ marginRight: "10px" }}>{averageRating} / 10</h3>
        <Progress percent={(averageRating / 10) * 100} showInfo={false} strokeWidth={10} />
        <p style={{ marginLeft: "10px" }}>{reviewsData.length} reviews</p>
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Button icon={<LeftOutlined />} onClick={prevSlide} disabled={currentIndex === 0} />
        <div style={{ display: "flex", overflow: "hidden", width: "100%" }}>
          {reviewsData.slice(currentIndex, currentIndex + reviewsPerPage).map((review) => (
            <Card key={review.id} style={{ width: "30%", margin: "0 10px", minHeight: "200px" }}>
              <h4>{review.name}</h4>
              <p style={{ fontSize: "12px", color: "gray" }}>{review.country}</p>
              <Rate disabled defaultValue={review.rating / 2} />
              <p>
                {expandedReviews[review.id]
                  ? review.text
                  : `${review.text.substring(0, 80)}... `}
                {!expandedReviews[review.id] && (
                  <Button type="link" onClick={() => handleExpand(review.id)}>Read more</Button>
                )}
              </p>
            </Card>
          ))}
        </div>
        <Button icon={<RightOutlined />} onClick={nextSlide} disabled={currentIndex + reviewsPerPage >= reviewsData.length} />
      </div>
    </div>
  );
};

export default ReviewsComponent;
