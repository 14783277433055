import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Skeleton, Card, List, Button, Switch, Checkbox, Input, message } from "antd";
import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import axios_ from "../util/axios";

const BookingResults = () => {
  const { hotelType } = useParams();
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({ location: "", minPrice: "", maxPrice: "" });

  useEffect(() => {
    fetchHotels();
  }, [hotelType, selectedFilters]);

  const fetchHotels = async () => {
    setLoading(true);
    try {
      const response = await axios_.get("/dashboard", {
        params: {
          type: hotelType,
          location: selectedFilters.location,
          minPrice: selectedFilters.minPrice,
          maxPrice: selectedFilters.maxPrice,
        },
      });
      setHotels(response.data);
    } catch (error) {
      message.error("Failed to fetch hotels. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", padding: "20px", gap: "20px" }}>
      {/* Sidebar Filters */}
      <div style={{ width: "250px", minWidth: "250px" }}>
        <h3>Filter by:</h3>

        <h4>Location</h4>
        <Input
          placeholder="Enter location"
          onChange={(e) => setSelectedFilters({ ...selectedFilters, location: e.target.value })}
        />

        <h4>Price Range</h4>
        <Input
          placeholder="Min price"
          type="number"
          onChange={(e) => setSelectedFilters({ ...selectedFilters, minPrice: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
        <Input
          placeholder="Max price"
          type="number"
          onChange={(e) => setSelectedFilters({ ...selectedFilters, maxPrice: e.target.value })}
        />
      </div>

      {/* Main Content */}
      <div style={{ flex: 1, minWidth: "300px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
          <h2>Hotels - {hotelType || "All Types"}</h2>
          <Switch
            checked={gridView}
            onChange={() => setGridView(!gridView)}
            checkedChildren={<AppstoreOutlined />}
            unCheckedChildren={<UnorderedListOutlined />}
          />
        </div>

        {loading ? (
          <List
            grid={{ gutter: 16, column: gridView ? 2 : 1 }}
            dataSource={[1, 2, 3]}
            renderItem={() => (
              <List.Item>
                <Card style={{ display: "flex", padding: "10px" }}>
                  <Skeleton.Image style={{ width: "150px", height: "100px", marginRight: "20px" }} active />
                  <Skeleton active paragraph={{ rows: 2 }} />
                </Card>
              </List.Item>
            )}
          />
        ) : (
          <List
            grid={{ gutter: 16, column: gridView ? 2 : 1 }}
            dataSource={hotels}
            renderItem={(hotel) => (
              <List.Item>
                <Card
                  hoverable
                  style={{
                    display: gridView ? "block" : "flex",
                    padding: "10px",
                    textAlign: gridView ? "center" : "left",
                  }}
                >
                  <img
                    src={hotel.image || "https://via.placeholder.com/150"}
                    alt={hotel.name}
                    style={{
                      width: "150px",
                      height: "100px",
                      margin: gridView ? "0 auto 10px" : "0 20px 0 0",
                    }}
                  />
                  <div>
                    <h3>{hotel.name}</h3>
                    <p>{hotel.location}</p>
                    <p>{hotel.price_per_night} {hotel.currency} per night</p>
                    <Button type="primary">View Deal</Button>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default BookingResults;
