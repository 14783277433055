import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Typography, Space, Button, Row, Col, Tabs, Tag } from "antd";
import { EnvironmentOutlined, HomeOutlined, GlobalOutlined } from "@ant-design/icons";
import axios_ from "../../../util/axios";
import { Link } from "react-router-dom";
import HotelAvailability from "../components/HotelAvailability";
import ClientReviews from "../components/ClientReviews";
import HotelGallery from "../components/HotelGallery";

const { Title, Paragraph } = Typography;

const HotelDetailsPage = () => {
  const { id } = useParams();
  const [hotel, setHotel] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHotelDetails = async () => {
      try {
        const response = await axios_.get(`/dashboard/${id}`);
        setHotel(response.data);
      } catch (error) {
        console.error("Error fetching hotel details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchHotelDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (!hotel) return <p>Hotel not found.</p>;

  const handleScroll = (targetId) => {
    const element = document.getElementById(targetId);
    if (element) element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      <div style={{ maxWidth: "1200px", width: "100%" }}>
      <Title level={4}>{hotel.name}</Title>
        <Card>
          <Tabs defaultActiveKey="overview" onTabClick={handleScroll} size="large">
            <Tabs.TabPane tab="Overview" key="overview" />
            <Tabs.TabPane tab="Details" key="details" />
            <Tabs.TabPane tab="Rooms" key="rooms" />
            <Tabs.TabPane tab="Reviews" key="reviews" />
            <Tabs.TabPane tab="Book Now" key="book" />
          </Tabs>
          <HotelGallery />
          <div id="overview" style={{ padding: "20px 0" }}>
            <Title level={3}>Overview</Title>
            <Row gutter={[16, 12]}>
            <text>The long hotel description stays here</text>
            </Row>
          </div>

          <div id="details" style={{ padding: "20px 0" }}>
            <Title level={3}>Hotel Details</Title>

            <Row gutter={[16, 16]}>
            <Col span={12}>

<Paragraph>{hotel.description}</Paragraph>
<Space direction="vertical">
  <Paragraph><EnvironmentOutlined /> {hotel.address}, {hotel.location}</Paragraph>
  <Paragraph><HomeOutlined /> {hotel.propertyType}</Paragraph>
  <Paragraph><GlobalOutlined /> <a href={`https://${hotel.website}`} target="_blank" rel="noopener noreferrer">{hotel.website}</a></Paragraph>
</Space>
</Col>
              <Col span={12}>
                <Paragraph><strong>Capacity:</strong> {hotel.maximumHotelCapacity} guests</Paragraph>
                <Paragraph><strong>Refund Policy:</strong> {hotel.refundPolicyAmount}</Paragraph>
                <Paragraph><strong>Booking Policy:</strong> {hotel.bookingPolicyAmount}</Paragraph>
              </Col>
            </Row>
            <Title level={4}>Amenities</Title>
            {hotel.amenities.map((amenity) => (
              <Tag key={amenity.id} color="blue">{amenity.amenityName}</Tag>
            ))}
          </div>

          <div id="rooms" style={{ padding: "20px 0" }}>
            <Title level={3}>Rooms</Title>
            <HotelAvailability apiEndpoint={`/dashboard/${id}/rooms`} />
          </div>

          <div id="reviews" style={{ padding: "20px 0" }}>
            <Title level={3}>Our Reviews</Title>
            <ClientReviews />
          </div>

          <div id="book" style={{ padding: "20px 0" }}>
            <Title level={3}>Book Now</Title>
            <Paragraph>
              <text>Satisfied with what you have seen? </text><Link to={{ pathname: "/bookHotel", state: { selectedHotel: hotel } }}>Book now!</Link>
            </Paragraph>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default HotelDetailsPage;
