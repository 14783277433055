import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "antd";

// Hotel Images Data
const hotelImages = [
  { id: 1, url: "/assets/hotelPictures/1.jpg", alt: "Hotel Room 1" },
  { id: 2, url: "/assets/hotelPictures/10.jpg", alt: "Hotel Room 2" },
  { id: 3, url: "/assets/hotelPictures/3.jpg", alt: "Hotel Lobby" },
  { id: 4, url: "/assets/hotelPictures/4.jpg", alt: "Outdoor View" },
  { id: 5, url: "/assets/hotelPictures/5.jpg", alt: "Swimming Pool" },
];

// HotelGallery Component
const HotelGallery = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  // Keyboard Event Listener
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") nextImage();
      if (event.key === "ArrowLeft") prevImage();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [currentImageIndex]);

  // Navigation Functions
  const nextImage = () => {
    setFadeIn(false); // Trigger fade-out
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % hotelImages.length);
      setFadeIn(true); // Trigger fade-in
    }, 200);
  };

  const prevImage = () => {
    setFadeIn(false); // Trigger fade-out
    setTimeout(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + hotelImages.length) % hotelImages.length
      );
      setFadeIn(true); // Trigger fade-in
    }, 200);
  };

  const currentImage = hotelImages[currentImageIndex];

  if (!currentImage) return <div>No images available.</div>;

  return (
    <div style={galleryContainer}>
      {/* Image Wrapper - Centered */}
      <div style={imageWrapper}>
        {/* Left Navigation Button */}
        <Button style={{ ...navButton, left: "20px" }} onClick={prevImage}>
          &lt;
        </Button>

        {/* Display Main Image */}
        <img
          src={currentImage.url}
          alt={currentImage.alt}
          style={{ ...imageStyle, opacity: fadeIn ? 1 : 0, transition: "opacity 0.3s ease-in-out" }}
        />

        {/* Right Navigation Button */}
        <Button style={{ ...navButton, right: "20px" }} onClick={nextImage}>
          &gt;
        </Button>
      </div>

      {/* Thumbnail Row - Centered */}
      <Row gutter={[10, 10]} justify="center" style={{ marginTop: "20px" }}>
        {hotelImages.map((image, index) => (
          <Col key={image.id}>
            <img
              src={image.url}
              alt={image.alt}
              style={{
                ...thumbnailStyle,
                border: index === currentImageIndex ? "3px solid #1890ff" : "none",
              }}
              onClick={() => setCurrentImageIndex(index)}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

// Styles
const galleryContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh", // Center vertically in the viewport
  textAlign: "center",
  padding: "20px",
};

const imageWrapper = {
  position: "relative",
  width: "800px",
  height: "500px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto", // Centers horizontally
};

const imageStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};

const navButton = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  color: "white",
  border: "none",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  zIndex: 10,
};

const thumbnailStyle = {
  width: "80px",
  height: "80px",
  borderRadius: "8px",
  cursor: "pointer",
  transition: "border 0.3s ease",
};

// Export Component
export default HotelGallery;
